.navigate{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    left: 120px;
    z-index: 998;
}

.menuToggle{
    position: relative;
    width: 50px;
    height: 50px;
    background: #a0ff00;
    border-radius: 70px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 15px 25px rgba(0,0,0,0.15);
    font-family: "JosefinSans";
    font-size: 2em;
    font-weight: 600;
    color: black;
    transition: 1.5s;
    text-align: center;
}

.menuToggle.active{
    transform: rotate(360deg);
}

.menu{
    position: absolute;
    width: 30px;
    height: 30px;
    background: #a0ff00;
    border-radius: 70px;
    z-index: -1;
    transition: transform 0.5s, width 0.5s, height 0.5s;
    transition-delay: 1s, 0.5s, 0.5s;
    transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 0 15px 25px rgba(0,0,0,0.15);
}

.menuToggle.active ~ .menu{
    width: 220px;
    height: 70px;
    z-index: 800;
    transform: translateY(-100px);
    transition-delay: 0s, 0.5s, 0.5s;
}

.menu::before{
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    background: #a0ff00;
    left: calc(50% - 8px);
    bottom: 4px;
    transform: rotate(45deg);
    border-radius: 2px;
    transition: 0.5s;
}

.menuToggle.active ~ .menu::before{
    transition-delay: 0.5s;
    bottom: -6px;
}

.menu ul{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 2px;
    gap: 30px;
    margin: 0px;
}

.menu ul li{
    list-style: none;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    transition: 0.25s;
    transition-delay: calc(0s + 0.1s);
}

.menuToggle.active ~ .menu ul li{
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition-delay: calc(0.75s + 0.1s);
}

.menu ul li a{
    display: block;
    font-size: 2.1em;
    text-decoration: none;
    color: black;
}

@media screen and (max-width: 767px) {
    .navigate{
        bottom: 50px;
        left: 70px;
    }

    .menuToggle{
        width: 40px;
        height: 40px;
        font-size: 1em;
    }

    .menuToggle.active ~ .menu{
        width: 150px;
        height: 50px;
        z-index: 800;
        transform: translateY(-80px);
        transition-delay: 0s, 0.5s, 0.5s;
    }

    .menu ul{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2px;
        gap: 20px;
    }
    
    .menu ul li a{
        font-size: 1.6em;
    }
}