.complaint{
    overflow: hidden;
    margin-top: 87px;
    padding-top: 50px;
    padding-bottom: 100px;
}

.complaintHeading {
    color: #a0ff00;
    display: flex;
    justify-content: center;
    text-align: center;
}

.complaintHeading h3 {
    font-weight: 700;
    font-size: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 40px;
}

.complaintSubContainer {
    width: 100%;
    margin: 0px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 7rem;
}

.complaintText {
    display: flex;
    justify-content: center;
    text-align: justify;
    width: 50%;
    margin: 0px;
    padding: 0px;
    padding-left: 0.5rem;
    flex-direction: column;
    vertical-align: middle;
    font-size: 1.2rem;
}

.complaintIllustration {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.complaintIllustration img {
    width: 60%;
    height: auto;
}

.complaintformmain{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.complaintform{
	padding: 20px;
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.complaintform form{
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
    gap: 15px;
}

.complaintform input{
	font-weight: 500;
	font-size: 17px;
	padding: 10px;
	color: black;
	margin-bottom: 12px;
	width: 90%;
	background: #a0ff00;
	border: none;
    border-radius: 20px;
}

.complaintform input:focus{
	background: white;
	outline: none;
}

.complaintform textarea{
	font-weight: 500;
	font-size: 17px;
	padding: 10px;
	color: black;
	margin-bottom: 12px;
	width: 90%;
	background: #a0ff00;
	border: none;
    border-radius: 20px;
}

.complaintform textarea:focus{
	background: white;
	outline: none;
}

.complaintform .submitform{
	background: #a0ff00;
	width: 30%;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: black;
	padding: 10px;
	margin: 0;
	border-radius: 5px;
}

@media only Screen and (max-width: 1200px){
    .complaintIllustration img {
        width: 80%;
    }
}

@media only Screen and (max-width: 1000px){
    .complaintText {
        width: 100%;
        order: 1;
    }
    
    .complaintIllustration {
        width: 100%;
        order: 0;
        padding-bottom: 2rem;
    }

    .complaintIllustration img {
        width: 60%;
    }

    .complaintSubContainer {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .complaintformmain{
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .complaintform{
        width: 70%;
    }
}


@media only Screen and (max-width: 600px) {
    .complaintIllustration img {
        width: 90%;
    }

    .complaintform{
        width: 100%;
    }
}