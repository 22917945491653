.footer{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter',sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #222222;
    color: white;
}

.footerupper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 50px;
    row-gap: 20px;
    margin-right: 50px;
    margin-left: 50px;
}

.footerlogo{
    width: 250px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.footerlogo .footerlogolink{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footericonsbox{
    width: 250px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    column-gap: 25px;
    font-size: 20px;
    cursor: pointer;
    color: #7F7F7F;
}

.footericonsbox a{
    color: #7F7F7F;
}

.footericonsbox p:hover{
    color: white;
}

.extra{
    width: 250px;
}

.footercontent{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
}

h1{
    font-size: 15px;
    font-weight: 600;
}

.footerproducts{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 200px;
}

.footerproducts ul{
    padding: 0;
    margin: 0;
    padding-bottom: 20px;
}

.footerproducts ul li{
    font-size: 15px;
    list-style: none;
    padding-top: 10px;
    color: #7F7F7F;
    cursor: pointer;
}

.footerproducts ul a{
    text-decoration: none;
}

.footerproducts ul li:hover{
    color: white;
}

.footerbelow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px #707070 solid;
    padding: 8px 50px;
}

.footertextbox p{
    color: #B7B7B7;
    font-size: 15px;
}

@media only screen and (max-width: 600px){
    .footerupper{
        justify-content: flex-start;
    }

    .footerproducts{
        width: 100%;
    }

    .footercontent{
        justify-content: flex-start;
    }
}