.glossary {
    overflow: hidden;
    margin-top: 87px;
    padding-top: 50px;
    padding-bottom: 100px;
}

.glossaryHeading {
    color: #A0ff00;
    display: flex;
    justify-content: center;
    text-align: center;
}

.glossaryHeading h3 {
    font-weight: 700;
    font-size: 3rem;
}

.glossarySubContainer {
    width: 100%;
    margin: 0px;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 7rem;
    display: flex;
    justify-content: flex-start;
}

.glossaryText {
    display: flex;
    text-align: justify;
    width: 100%;
    margin: 0px;
    padding: 0px;
    padding-left: 0.5rem;
    flex-direction: column;
    vertical-align: middle;
    font-size: 1.2rem;
}

.glossaryPagesLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.glossaryLink {
    text-decoration: none;
    color: darkgray;
}

.glossaryLink:hover {
    text-decoration: underline;
    color: darkgray;
}

.contentmaintext {
    color: darkgray;
}


@media only screen and (max-width: 1200px) {}

@media only screen and (max-width: 1000px) {
    .glossaryText {
        width: 100%;
        order: 1;
    }

    .glossarySubContainer {
        padding-left: 2rem;
        padding-right: 2rem;
    }
}

@media only screen and (max-width: 600px) {}