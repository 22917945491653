.whatsapp_float {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    bottom: 50px;
    right: 95px;
    background-color: #a0ff00;
    color: black !important;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 998;
}

@media screen and (max-width: 767px) {
    .whatsapp_float {
        width: 40px;
        height: 40px;
        right: 40px;
        font-size: 22px;
    }
}