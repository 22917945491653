.videoblock{
    position: relative;
    height: 80vh;
    width: 100%;
    overflow: hidden;
    background: black;
}

.videoblock .videoweb{
    width: inherit;
    position: absolute;
    width: 100%;
    top: 100px;
}

.videoblock .videomob{
    display: none;
}

@media only screen and (max-width: 1440px){
    .videoblock .videoweb{
        top: 180px;
    }
}

@media only screen and (max-width: 1024px){
    .videoblock .videoweb{
        top: 150px;
    }
}

@media only screen and (max-width: 768px){
    .videoblock .videoweb{
        top: 180px;
        width: 110%;
    }
}

@media only screen and (max-width: 500px){
    .videoblock .videoweb{
        display: none;
    }

    .videoblock .videomob{
        display: block;
        width: inherit;
        position: absolute;
        height: 100%;
        top: 100px;
    }
}

@media only screen and (max-width: 964px){
    .videoblock{
        height: 70vh;
    }
}

@media only screen and (max-width: 430px){
    .videoblock{
        height: 65vh;
    }
}

@media only screen and (max-width: 399px){
    .videoblock{
        height: 60vh;
    }
}