.header{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter",sans-serif;
    background: black;
    transition: all 0.5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 999;
}

.headermain{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
    transition: all 0.5s;
}

.headercontent{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 60%;
}

.headerlogo{
    width: 15%;
    display: flex;
    flex-direction: column;
}

.headerlogo .headerlogolink{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.headerlinks{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
}

.headerlinks .mainbar{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    column-gap: 20px;
    padding-left: 20px;
    transition: all 0.5s;
    margin: 0;
}

.headerlinks .mainbar li{
    font-size: 18px;
    list-style: none;
    color: #7F7F7F;
    cursor: pointer;
}

.headerlinks .mainbar li a{
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
    padding-top: 36.5px;
    padding-bottom: 36.5px;
    text-decoration: none;
    color: #7F7F7F;
    cursor: pointer;
}

.headerlinks .mainbar li a:hover{
    color: white;
}

.headerlinks .mainbar li:hover{
    color: white;
}

.headerbutton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.headercreateaccount a{
    padding: 15px 30px;
    height: 40px;
    font-size: 13px;
    font-weight: 600;
    border-radius: 10px;
    background-color: #a0ff00;
    border: none;
    color: #212224;
    cursor: pointer;
    text-decoration: none;
}

.headercreateaccount a{
    text-decoration: none;
    color: #212224;
}

.dropdown{
    display: flex !important;
    align-items: center !important;
}

.underdropdown{
    position: fixed;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    top: 87px;
    opacity: 0;
    background-color: black;
    transition: all 0.5s;
    transform: translateX(-20px) translateY(0px);
    visibility: hidden;
    padding-top: 20px;
}

.underdropdown ul{
    padding: 0;
}

.underdropdown ul a{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.underdropdown ul li{
    padding-bottom: 20px !important;
    padding-top: 0px !important;
    padding-left: 20px;
    padding-right: 20px;
}

.mainbar .dropdown:hover .underdropdown{
    transform: translateX(-20px) translateY(0px);
    opacity: 1;
    visibility: visible;
}

.mobilemenu{
    display: none;
}

@media only screen and (max-width: 1103px){
    .headerlinks .mainbar li a{
        font-size: 16px;
    }
}

@media only screen and (max-width: 1022px){
    .headerlinks .mainbar{
        column-gap: 10px;
    }
}

@media only screen and (max-width: 1000px){
    .header{
        height: 100px;
        align-content: flex-start;
    }

    .headermain{
        height: 100px;
        transition: all 0.5s;
        transform: translateY(14px);
    }

    .headercontent{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
        margin-top: 50px;
        order: 2;
        transform: translateY(100px);
    }

    .headerlinks .mainbar{
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        padding-left: 0px;
        transition: all 0.5s;
        margin: 0;
    }

    .headerlinks .mainbar li{
        font-size: 20px;
        list-style: none;
        color: #7F7F7F;
        cursor: pointer;
        padding-top: 0px;
        padding-bottom: 30px;
    }

    .headerlinks .mainbar li a{
        font-size: 20px;
        padding-top: 0px !important;
        padding-bottom: 0px !important;
        justify-content: center;
    }

    .headerlogo{
        width: 245px;
        order: 0;
    }

    .headercreateaccount{
        order: 1;
    }

    .mobilemenu{
        display: block;
        height: 30px;
    }

    .header.active{
        height: 100vh;
        overflow: scroll;
    }

    .dropdown{
        display: flex !important;
        align-items: center !important;
        flex-direction: column;
        transition: all 1s;
    }

    .underdropdown{
        position: static;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        flex-wrap: nowrap;
        top: 87px;
        background-color: black;
        transform: translateX(0px) translateY(0px);
        padding-top: 0px;
        transition: all 1s;
        overflow: hidden;
        height: 0px;
    }

    .mainbar .dropdown:hover .ud1{
        transition: all 0.5s;
        display: flex;
        padding-top: 20px;
        transform: translateX(0px) translateY(0px);
        height: 370px;
    }

    .mainbar .dropdown:hover .ud2{
        transition: all 0.5s;
        display: flex;
        padding-top: 20px;
        transform: translateX(0px) translateY(0px);
        height: 220px;
    }

    .mainbar .dropdown:hover .ud3{
        transition: all 0.5s;
        display: flex;
        padding-top: 20px;
        transform: translateX(0px) translateY(0px);
        height: 170px;
    }

    .mainbar .dropdown:hover .ud4{
        transition: all 0.5s;
        display: flex;
        padding-top: 20px;
        transform: translateX(0px) translateY(0px);
        height: 70px;
    }

    .mainbar .dropdown:hover .ud5{
        transition: all 0.5s;
        display: flex;
        padding-top: 20px;
        transform: translateX(0px) translateY(0px);
        height: 600px;
    }
}

@media only screen and (max-width: 550px){
    .headermain{
        height: 100px;
        transition: all 0.5s;
        transform: translateY(20.625px);
    }

    .headercreateaccount a{
        padding: 10px 30px;
        height: 40px;
        font-size: 12px;
        font-weight: 600;
        border-radius: 10px;
        background-color: #a0ff00;
        border: none;
        color: #212224;
        cursor: pointer;
    }

    .headerlogo{
        width: 200px;
        order: 0;
    }
}

@media only screen and (max-width: 450px){
    .headermain{
        height: 100px;
        transition: all 0.5s;
        transform: translateY(20.625px);
    }

    .headercreateaccount{
        display: none;
    }
}