.process{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.processunder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    min-height: 100px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.processfirst{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 50px;
}

.processtext h1{
    font-size: 40px;
}

.processbutton a{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: 3px solid #a0ff00;
    border-radius: 10px;
    padding: 20px;
    text-decoration: none;
    transition: all 0.5s;
}

.processbutton a:hover{
    border: 3px solid #a0ff00;
}

.processsecond{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 30px;
}

.processsecond .processbox{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    min-height: 400px;
    color: white;
    background-color: #212224;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    overflow: hidden;
}

.processbox div{
    display: flex;
    flex-direction: column;
    width: 350px;
    row-gap: 20px;
}

.processbox div .processimage{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 300px;
}

.processbox img{
    width: 85%;
    height: auto;
    margin-bottom: 20px;
}

.processbox h1{
    font-size: 22px;
}

.processbox p{
    font-size: 15px;
    color: #B9BABA;
}

@media only Screen and (max-width: 1500px){
    .processsecond{
        justify-content: space-evenly;
    }

    .box2{
        order: 2;
    }
}

@media only Screen and (max-width: 1000px){
    .processsecond{
        row-gap: 30px;
    }

    .processsecond .processbox{
        width: 100%;
    }

    .processbox div{
        width: 80%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .processbox img{
        width: 90%;
        height: auto;
    }

    .box2{
        order: 1;
    }

    .box3{
        order: 2;
    }
}

@media only Screen and (max-width: 400px){
    .processtext h1{
        font-size: 30px;
    }

    .processbutton a{
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border: 3px solid #a0ff00;
        border-radius: 10px;
        padding: 20px;
        text-decoration: none;
    }
}